.intro {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 100vw;
    min-width: 100vw;
    height: 100vh;
    @media only screen and (max-width: 1024px) {
        height: 80vh;
    }
    @media only screen and (max-width: 768px) {
        height: 100vh;
    }
    &:after {
        content: '';
        background: #000;
        width: 60%;
        left: -20%;
        z-index: 3;
        position: absolute;
        height: 120vh;
        -webkit-transform: skew(-20deg);
        -moz-transform: skew(-20deg);
        -o-transform: skew(-20deg);
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
        @media only screen and (max-width: 1200px) {
            width: 75%;
        }
        @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 240px;
            transform: skewY(5deg);
            left: 0;
            right: 0;
            top: 405px;
        }
        @media only screen and (max-width: 768px) {
            top: 300px;
        }
        @media only screen and (max-width: 767px) {
            top: 140px;
        }
    }
    &:before {
        content: ' ';
        background: transparent;
        position: absolute;
        width: 60%;
        height: 100%;
        right: 0%;
        top: 0;
        z-index: 10;
        background-image: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
        @media only screen and (max-width: 1200px) {
            width: 30%;
        }
        @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 50%;
            bottom: 0;
            top: auto;
            background-image: linear-gradient(rgba(255, 255, 255, 0), white);
        }
    }
}

.intro-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 1024px) {
        flex-flow: column;
    }
}

.intro-left {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    position: relative;
    max-width: 40%;
    min-height: 100vh;
    justify-content: center;
    z-index: 4;
    margin-top: -60px;
    @media only screen and (max-width: 1200px) {
        max-width: 55%;
    }
    @media only screen and (max-width: 1024px) {
        max-width: 100%;
        min-height: 600px;
        justify-content: center;
        align-items: center;
        margin-top: 0;
        background: #000;
    }
    @media only screen and (max-width: 768px) {
        min-height: 500px;
    }
    @media only screen and (max-width: 767px) {
        min-height: 350px;
        padding: 5%;
    }
}

.intro-right {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    max-width: 100%;
    width: 100%;
    height: 80vh;
    justify-content: flex-start;
    margin-left: -7%;
    margin-top: -11%;
    @media only screen and (max-width: 1024px) {
        max-width: 100%;
        margin: 0;
    }
}

.icon-wrapper {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    // width: 100%;
    height: 100%;
    animation: scroll-left 20s linear infinite;
    & svg {
        position: relative;
        width: 60px;
        max-height: 65px;
        @media only screen and (max-width: 767px) {
            width: 40px;
        }
    }
}

.icon-wrapper-2 {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    // width: 100%;
    height: 100%;
    animation: scroll-left 20s linear infinite;
    & svg {
        position: relative;
        width: 50px;
        max-height: 50px;
        @media only screen and (max-width: 767px) {
            width: 35px;
        }
    }
}

.icon-wrapper-3 {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    // width: 100%;
    height: 100%;
    animation: scroll-left 20s linear infinite;
    & svg {
        position: relative;
        width: 40px;
        max-height: 40px;
        @media only screen and (max-width: 767px) {
            width: 30px;
        }
    }
}

.icon-wrapper-4 {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    // width: 100%;
    height: 100%;
    animation: scroll-left 20s linear infinite;
    & svg {
        position: relative;
        width: 30px;
        max-height: 30px;
        @media only screen and (max-width: 767px) {
            width: 25px;
        }
    }
}

.icon-wrapper-5 {
    position: absolute;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    // width: 100%;
    height: 100%;
    animation: scroll-left 20s linear infinite;
    & svg {
        position: relative;
        width: 25px;
        max-height: 25px;
        @media only screen and (max-width: 767px) {
            width: 20px;
        }
    }
}


.icon-cont-1 {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    height: 100%;
    animation: scroll-left 20s linear infinite;
}

.icon-cont-2 {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.icon-cont-3 {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.icon-cont-4 {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.icon-cont-5 {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}


.bdl-logo-cont {
    position: relative;
    padding-left: 5em;
    @media only screen and (max-width: 1024px) {
        padding-left: 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
    }
    & img  {
        max-width: 100%;
        margin-left: -8px;
        align-self: center;
        @media only screen and (max-width: 1024px) {
            max-width: 55%;
            margin-left: 0;
        }
        @media only screen and (max-width: 767px) {
            max-width: 75%;
        }
    }
}

.hero-txt {
    padding-left: 5em;
    padding-right: 5em;
    text-align: left;
    color: #fff;
    @media only screen and (max-width: 1024px) {
        padding: 0;
        text-align: center;
        max-width: 55%;
    }
    @media only screen and (max-width: 767px) {
        max-width: 100%;
    }
    & h1 {
        font-size: 2.6em;
        @media only screen and (max-width: 767px) {
            font-size: 1.8em;
        }
    }
    & p {
     font-size: .75em;
     line-height: 1.5em;
     color: #a3a3a3;
      @media only screen and (max-width: 1024px) {
        font-size: 1.2em;
      }
      @media only screen and (max-width: 767px) {
        font-size: 0.9em;
      }
    }
}