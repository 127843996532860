$finalHeight: 100vh;
$finalWidth: 3 * $finalHeight;
$scrollBarHeight: 1px;

* {
  box-sizing: border-box;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html, body {
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

::-webkit-scrollbar-button {
  width: $scrollBarHeight;
  height: $scrollBarHeight;
}

.App {
  position: relative;
  margin: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: 100vh; 
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  align-content: center;
  border: 1px solid red;
}

// .horiz {
//   position: absolute;
//   display: block;
//   top: 0;
//   left: 0;
//   width: calc(#{$finalHeight} + #{$scrollBarHeight});
//   max-height: $finalWidth;
//   margin: 0;
//   padding-top: $scrollBarHeight;
//   overflow-y: auto;
//   overflow-x: hidden;
//   transform: rotate(-90deg) translateY(-$finalHeight);
//   transform-origin: right top;
//   overscroll-behavior: none;
// }