#services {
  @media only screen and (max-width: 1024px) {
    z-index: 2;
  }
}

.services {
    position: relative;
    height: 100vh;
    // min-width: 100vw;
    width: 100%;
    @media only screen and (max-width: 1024px) {
      height: 100%;
      z-index: 3;
      &:before {
        position: absolute;
        content: "";
        width: 100%;
        height: 240px;
        transform: skewY(5deg);
        left: 0;
        top: -50px;
        background-color: #000000;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
      }
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 240px;
        transform: skewY(5deg);
        left: 0;
        bottom: -50px;
        background-color: #000000;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
      }
    }
}

.s3-cont {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    background-color: #000000;
    padding-right: 80px;
    @media only screen and (max-width: 1024px) {
      padding: 100px 80px;
      z-index: 2;
    }
    @media only screen and (max-width: 767px) {
      padding: 5%;
    }
}

.s3-skew {
    position: absolute;
    top: 0;
    left: -125em;
    right: 0;
    bottom: 0;
    overflow: hidden;
    z-index: -1;
    contain: strict;
    min-height: 180vh;
    -webkit-transform: skewY(-65deg);
    -moz-transform: skewY(-65deg);
    -ms-transform: skewY(-65deg);
    -o-transform: skewY(-65deg);
    transform: skewY(-65deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    background: #000000;
    @media only screen and (max-width: 1024px) {
      display: none;
    }
    @media only screen and (min-width: 1650px) {
      left: -130em;
    }
    @media only screen and (min-width: 1800px) {
      left: -145em;
    } 
    @media only screen and (min-width: 1950px) {
      left: -165em;
    }
}

.s3-section-heading {
  position: relative;
  margin-top: -3em;
  margin-bottom: 3em;
  @media only screen and (max-width: 1024px) {
    margin-top: 0;
  }
   & h3 {
    font-size: 6em;
    color: #fff;
    margin: 0;
    @media only screen and (max-width: 767px) {
      font-size: 4.5em;
    }
    @media only screen and (max-width: 400px) {
      font-size: 4em;
    }
   }
}

.s3-inner-cont {
 position: relative;
 width: 100%;
 display: -webkit-box;
 display: -moz-box;
 display: -ms-flexbox;
 display: -webkit-flex;
 display: flex;
 flex-flow: column;
 align-items: flex-start;
 justify-content: center;
  // @media only screen and (max-width: 767px) {
  //   align-items: center;
  // }
}

.s3-card-cont {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-right: 2%;
  @media only screen and (max-width: 1024px) {
    flex-flow: row wrap;
    padding-right: 0;
    justify-content: center;
    align-items: center;
    margin: 0;
  }
}

.s3-card {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  min-width: 350px;
  width: 350px;
  height: 350px;
  justify-content: space-between;
  border: 0.5px solid #a3a3a3;
  padding: 2%;
  -webkit-transition: all 1s ease;
  border-radius: 5px;
  margin-left: 35px;
  @media only screen and (max-width: 1024px) {
    margin: 20px;
    border: 1px solid #a3a3a3;
  }
  @media only screen and (max-width: 767px) {
    padding: 5%;
    margin: 30px 0px 0px 0px;
  }
  @media only screen and (max-width: 400px) {
    margin: 30px 0px 0px 0px;
    min-width: 300px;
    width: 300px;
  }
  &:first-child {
    margin-left: 0;
    @media only screen and (max-width: 1024px) {
      margin: 20px;
    }
    @media only screen and (max-width: 767px) {
      margin: 0;
    }
  }
  &:hover {
    background-color: #3d3d3d;
    box-shadow: 2px 2px 5px rgba(255,255,255,0.3),
            10px 10px 15px rgba(255,255,255,0.3),
            -1px -1px 30px rgba(255,255,255,0.1);
    }
}

.s3-card-top {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
}

.s3-svg {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  & svg {
    width: 60px;
    height: 60px;
  }
}

.s3-card-head {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: flex-start;
   & h4 {
    margin: 0;
    color: #ffffff;
    margin-left: 8%;
    font-size: 1em;
  }
}

.s3-card-mid {
  position: relative;
  & p {
    font-size: 0.65em;
    line-height: 1.8em;
    color: #fff;
  }
}

.s3-servBtn {
  position: relative;
  text-decoration: none !important;
  & text {
    text-decoration: none !important;
  }
  &:hover {
    cursor: pointer;
    text-decoration: none !important;
  }
}

.s3-learn-more {
  color: #fff;
  & a  {
    text-decoration: none;
  }
}

.serv-btn-txt {
  text-shadow: 2px 2px 4px #000;
  // font-weight: 600;
}