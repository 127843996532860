.cp-contact {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 1024px) {
        height: 100%;
    }
}

.cp-contact-form {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 1024px) {
        height: 100%;
    }
}

.cp-contact-section-head {
    position: relative;
    margin: 0;
    @media only screen and (max-width: 767px) {
        margin: 0;
    }
     & h3 {
      font-size: 3em;
      color: #000;
      margin: 0;
      margin-bottom: 50px;
     }
  }

  .cp-contact-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        flex-flow: column;
    }
}

.cp-form-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    @media only screen and (max-width: 768px) {
        min-width: 100%;
    }
}

.cp-contact-form {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 700px;
    @media only screen and (max-width: 767px) {
        width: 100%;
    }
    & input[type="submit"] {
        position: relative;
        width: 50%;
        background-color: #ff3800;
        height: 40px;
        border-radius: 5px;
        font-size: 0.8em;
        font-weight: 600;
        color: #fff;
        border: none;
        -webkit-transition: all 1s ease;
        -webkit-appearance: none;
        text-transform: uppercase;
        font-weight: 900;
        cursor: pointer;
        &:hover {
            background-color: #ccc;
            color: #ff3800;
        }
    }
}

.cp-form-input-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    width: 100%;
    & label {
        color: #000;
        margin-bottom: 8px;
        font-weight: 600;
    }
    & input {
        min-width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: none;
        margin-bottom: 20px;
        min-height: 30px;
        padding: 0 3%;
    }
    & textarea {
        min-width: 100%;
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: none;
        margin-bottom: 20px;
        min-height: 50px;
        padding: 5px 3%;
    }
}