.blog-temp-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    background-color: #fafafa;
    overflow-x: hidden;
}

.blog-temp-container-inner {
    position: relative;
    width: 100%;
    background-color: #fafafa;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
}

.blog-temp-cont {
    position: relative;
    width: 60%;
    max-width: 1400px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
        width: 80%;
    }
    @media screen and (max-width: 767px) {
        width: 90%;
    }
}

.blog-temp-title-header {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 5%;
    z-index: 1;
    min-width: 100%;
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #000;
      transform-origin: top right;
      transform: skew(-45deg, 0deg);
      -ms-transform: skew(-45deg, 0deg);
      -webkit-transform: skew(-45deg, 0deg);
      @media screen and (max-width: 1024px) {
        width: 110%;
       }
       @media screen and (max-width: 768px) {
        width: 140%;
       }
       @media screen and (max-width: 767px) {
        width: 160%;
        }
    }
}

.blog-temp-title-header-logo-cont {
    position: relative;
    & a {
        position: relative;
     & img {
        width: 350px;
        height: auto;
        @media screen and (max-width: 767px) {
            width: 220px;
        }
     }
    }   
}

.blog-temp-head-title-cont {
    position: relative;
    & h1 {
        font-size: 3.5em;
        margin-top: 50px;
        margin-bottom: 0;
        max-width: 75%;
        color: #fff;
        @media screen and (max-width: 767px) {
            font-size: 2.2em;
        }
    }
}

.blog-temp-title-cont {
    position: relative;
    width: 100%;
    padding-top: 80px;
    & h1 {
        font-size: 3.5em;
    }
}

.blog-temp-auth-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: 767px) {
        flex-flow: column;
    }
}

.auth-info-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap; 
    width: 70%;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
    }
}

.auth-img {
    position: relative;
    max-width: 100px;
    & img {
        border-radius: 5px;
        max-width: 100px;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
    }
}

.blog-temp-share-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap; 
    width: 30%;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 767px) {
        width: 100%;
        justify-content: center;
        margin-top: 20px;
    }
}

.blog-temp-article-info {
    position: relative;
    padding-left: 20px;
}

.auth-name {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    height: 30px;
    align-items: center;
    & h3 {
        margin: 0;
        padding: 0;
        margin-left: 8px;
    }
}

.blog-temp-body {
    position: relative;
    width: 100%;
    padding-top: 80px;
    font-size: 18px;
    line-height: 30px;
    font-weight: 500;
    & div {
        margin-bottom: 30px;
    }
    & img {
        display: block;
        min-width: 100%;
        width: 100%;
        height: auto;
    }
    & a {
        color: #ff3800;
        text-decoration: none;
        font-weight: 600;
    }
    & h2  {
        font-size: 2.5em;
        font-size: 1.8em;
        line-height: 1.3em;
        font-weight: 700;
        margin-top: 40px;
    }
    & h3 {
        font-size: 1.8em;
        font-weight: 600;
    }
    & h4 {
        position: relative;
        font-size: 1.5em;
        line-height: 1.5em;
        & span {
            background-color: #00e7f7;
            padding: 0 5px;
        }
    }
    & ul {
        position: relative;
        & li {
            position: relative;
            font-weight: 600;
            & span {
                font-weight: 400;
            }
        }
    }
}

.date-time {
    font-size: 0.9em;
}