#portfolio {
  position: relative;
  z-index: 5;
  @media only screen and (max-width: 1024px) {
    z-index: 1;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      height: 240px;
      transform: skewY(5deg);
      left: 0;
      bottom: -50px;
      background-color: #fafafa;
      // box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
    }
  }
}

.portfolio {
    height: 100vh;
    // min-width: 100vw;
    width: 100%;
    padding: 0 5%;
    background-color: #fafafa;
    z-index: 4;
    @media only screen and (max-width: 1024px) {
      z-index: 1;
      height: 100%;
      padding: 150px 80px 100px 80px;
    }
    @media only screen and (max-width: 1024px) {
      padding: 150px 5% 20px 5%;
    }
}

.s4-cont {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    z-index: 3;
    @media only screen and (max-width: 1024px) {
      z-index: 1;
      flex-flow: column;
    }
  }
  
  .s4-txt-cont {
    width: 575px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    @media only screen and (max-width: 1024px) {
      // z-index: 0;
      width: 100%;
      align-items: flex-start;
    }
  }

  .s4-section-head {
    position: relative;
    margin-top: -10em;
    @media only screen and (max-width: 1024px) {
      margin-top: 0;
    }
     & h3 {
        font-size: 6em;
        text-shadow: 2px 2px 4px #a3a3a3;
        margin: 0;
        @media only screen and (max-width: 767px) {
          font-size: 4.5em;
        }
        @media only screen and (max-width: 400px) {
          font-size: 4em;
      }
     }
  }
  
  .s4-subtext {
      max-width: 80%;
      position: relative;
      margin-top: 2em;
      margin-left: 10em;
      @media only screen and (max-width: 1024px) {
        margin-left: 2em;
        max-width: 65%;
      }
      @media only screen and (max-width: 767px) {
        margin-left: 5%;
      }
      @media only screen and (max-width: 450px) {
        max-width: 75%;
      }
  }
  
  .s4-subhead {
      font-size: 1em;
      font-weight: 800;
      @media only screen and (max-width: 1024px) {
        font-size: 2em;
      } 
      @media only screen and (max-width: 767px) {
        font-size: 1.5em;
      }
  }
  
  .s4-text {
      font-size: 0.8em;
      line-height: 1.5em;
      @media only screen and (max-width: 1024px) {
        font-size: 1.4em;
      }
      @media only screen and (max-width: 767px) {
        font-size: 0.9em;
      }
  }
  
  .s4-right-cont {
    width: 670px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    position: relative;
    align-items: flex-start;
    justify-content: center;
    padding-left: 30px;
     @media only screen and (min-width: 1600px) {
      width: 745px;
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;
      padding-left: 0;
      justify-content: flex-end;
    }
  }
  
  .s4-svg-cont {
    width: 100%;
    background-image: url('../../imgs/database.jpg');
    background-size: cover;
    background-position: 0 0;
    will-change: transform;
    right: 0em;
    z-index: 0;
    @media only screen and (max-width: 1024px) {
      width: 75%;
      margin-top: -5em;
      z-index: -1;
    }
    @media only screen and (max-width: 767px) {
      margin-top: -2em;
    }
    @media only screen and (max-width: 400px) {
      margin-top: 2em;
    }
  }
  
  .s4-svg-mask {
    position: relative;
    width: 132%;
    color: #fafafa;
    margin-top: -12em;
    // @media only screen and (min-width: 1650px) {
    //   width: 140%;
    // }
    @media only screen and (max-width: 1024px) {
      margin-top: 0em;
      width: 133%;
      margin-bottom: -13%;
      margin-left: -1%;
    }
    // @media only screen and (max-width: 400px) {
    //   margin-bottom: -13%;
    // }
    & svg {
      position: relative;
    }
  }
  
  .s4-skew {
    position: absolute;
    top: 0;
    left: -40em;
    right: -90em;
    bottom: 0;
    overflow: hidden;
    z-index: 0;
    contain: strict;
    min-height: 180vh;
    -webkit-transform: skewY(-65deg);
    -moz-transform: skewY(-65deg);
    -ms-transform: skewY(-65deg);
    -o-transform: skewY(-65deg);
    transform: skewY(-65deg);
    -webkit-transform-origin: center center;
    -moz-transform-origin: center center;
    -ms-transform-origin: center center;
    -o-transform-origin: center center;
    transform-origin: center center;
    background: #fafafa;
    @media only screen and (min-width: 1650px) {
      left: -25em;
    }
    @media only screen and (min-width: 1850px) {
      left: -20em;
    }
    @media only screen and (min-width: 1950px) {
      left: -12em;
    }
    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  .s4-exit-btn {
    color: #fff;
    padding: 20px 50px;
    overflow: hidden;
    background: #ff3800;
    font-size: .75em;
    font-weight: 900;
    letter-spacing: 0.1em;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;
    float: left;
    margin-top: 1em;
    margin-right: 1em;
    box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
    border-radius: 5px;
    -webkit-transition: all 1s ease;
    &:hover {
      background-color: #a3a3a3;
      color: #ff3800;
    }
}
  

// .s4-ani-image {
//     position: absolute;
//     top: 0;
//     z-index: -1;
//     & img {
//         position: relative;
//         margin-left: -5em;
//     }
// }

