.terms-cont {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.terms-inner {
    position: relative;
    width: 65%;
    max-width: 1200px;
    padding: 80px 0;
}