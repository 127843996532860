#MobileMenu {
    // position: fixed;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: flex-end;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.7);
    top: 0;
    left: 0;
    z-index: 10;
}

.mobile-menu-inner {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-evenly;
    padding: 5% 2% 2% 2%;
    z-index: 11;
    background-color: #000;
    width: 320px;
    height: 100vh;
}

.cir-menu-item {
    position: relative;
    margin-bottom: 30px;
    & a {
        position: relative;
        color: #fff !important;
        text-decoration: none;
        font-size: 1.4em;
        & h4 {
            margin: 0;
            &:hover {
                color:#00e7f7;
            }
        }
    }
}

.abs-menu-logo {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    & a {
      position: relative;
      width: 100%;
      & img {
        position: relative;
        max-width: 100%;
    }
    }
}

.cir-menu-sub {
    position: relative;
    & ul {
        list-style: none;
        padding-left: 20px;
     & li {
        font-size: 0.9em;
        line-height: 1.5em;
        &:hover {
            color:#00e7f7;
        }
     }
    }
}



// ---------------------- REACT BURGER MENU STYLES --------------------------


.bm-burger-button {
    position: fixed;
    width: 40px;
    height: 25px;
    right: 36px;
    top: 36px;
    background-color: #3d3d3d;
    padding: 30px;
    box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
    border-radius: 5px;
    @media screen and (max-width: 767px) {
      padding: 20px;
      right: 15px;
    }
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #fff;
    height: 3px !important;
    border-radius: 30px;
    max-width: 35px !important;
    @media screen and (max-width: 767px) {
      max-width: 25px !important;
    }
    &:nth-of-type(1) {
        top: 40% !important;
        left: 20% !important;
        @media screen and (max-width: 767px) {
          left: 19% !important;
        }
      }
    &:nth-of-type(2) {
        display: none !important;
    }
    &:nth-of-type(3) {
        top: 60% !important;
        left: 20% !important;
        @media screen and (max-width: 767px) {
          left: 19% !important;
        }
    }
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #ff3800;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #a3a3a3;
    height: 35px !important;
  }
  
  .bm-cross-button {
      height: 35px !important;
      width: 35px !important;
  }
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #3d3d3d;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #3d3d3d;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    padding-top: 80px;
    // @media screen and (max-width: 1024px) {
    //     max-height: 50%;
    // }
    // @media screen and (max-width: 768px) {
    //     max-height: 70%;
    // }
    // @media screen and (max-width: 400px) {
    //     max-height: 100%;
    // }
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.8);
  }

  .bmenu-link-cont {
    position: relative;
    display: flex;
    flex-flow: column;
    border: none !important;
    outline: none !important;
  }
