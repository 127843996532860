.about-page-cont {
    position: relative;
    width: 100%;
    padding: 10% 5%;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.about-page-mission {
    position: relative;
    display: flex;
    flex-flow: column;
    & svg {
        max-width: 50px;
    }
    & h3 {
        font-size: 3em;
        margin: 0;
        margin-top: 10px;
        @media only screen and (max-width: 767px) {
            font-size: 2em;
        }
    }
    & p {
        font-weight: 500;
        line-height: 2em;
        font-size: 20px;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.about-page-inner {
    position: relative;
    max-width: 1000px;
    width: 100%;
}

.about-page-sep {
    position: relative;
    width: 100%;
    height: 20px;
    margin: 50px 0;
    display: flex;
    align-items: center;
    & hr {
        position: relative;
        width: 100px;
        height: 2px;
        background-color: #ccc;
        margin-left: 0;
    }
}

.about-page-top {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: 1;
    @media only screen and (max-width: 900px) {
        flex-flow: column;
    }
}

.about-page-top-cont {
    position: relative;
    width: 50%;
    flex: 1 1;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
}

.about-page-top-left {
    padding-right: 50px;
    & h5 {
        font-size: 2em;
        margin: 0;
    }
    & p {
        line-height: 1.5em;
        @media only screen and (max-width: 767px) {
            font-size: 16px;
        }
    }
}

.about-page-top-right {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    z-index: 1;
    @media only screen and (max-width: 900px) {
        margin-top: 50px;
        justify-content: space-around;
    }
}

.ap-stats-cont {
    position: relative;
    width: 48%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #3d3d3d;
    border-radius: 12px;
    margin-bottom: 4%;
    @media only screen and (max-width: 900px) {
        padding: 20px 0;
    }
    & h6 {
        margin: 0;
        font-size: 1em;
        text-align: center;
        color: #fff;
        @media only screen and (max-width: 499px) {
            font-size: 0.8em;
        }
    }
    & h5 {
        margin: 0;
        font-size: 3.5em;
        margin-bottom: 50px;
        text-align: center;
        color: #fff;
        @media only screen and (max-width: 499px) {
            font-size: 2em;
        }
    }
}

.about-page-phil {
    position: relative;
    padding: 50px 80px;
    background-color: #3d3d3d;
    display: flex;
    flex-flow: column;
    width: 100%;
    border-radius: 12px;
    @media only screen and (max-width: 767px) {
        padding: 50px 20px;
    }
    & h3 {
        position: relative;
        margin: 0;
        margin-bottom: 50px;
        font-size: 2em;
        color: #fff;
    }
}

.ap-phil-row {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 80px;
    &:last-child {
        margin-bottom: 50px;
    }
}

.ap-phil-num {
    position: relative;
    width: 25%;
    display: flex;
    & h6 {
        color: #fff;
        font-weight: 700;
        font-size: 5em;
        margin: 0;
        @media only screen and (max-width: 900px) {
            font-size: 3em;
        }
        @media only screen and (max-width: 499px) {
            font-size: 2em;
        }
        & span {
            font-weight: 200;
        }
    }
}

.ap-phil-list {
    position: relative;
    width: 75%;
    display: flex;
    flex-flow: column;
    & h4 {
        margin: 0;
        margin-bottom: 20px;
        color: #fff;
        font-size: 2em;
        @media only screen and (max-width: 900px) {
            font-size: 1.5em;
        }
        @media only screen and (max-width: 499px) {
            font-size: 1.2em;
        }
    }
    & p {
        margin: 0;
        color: #fff;
        @media only screen and (max-width: 499px) {
            font-size: 14px;
        }
    }
}

.about-page-team {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: column;
    & h3 {
        margin: 0;
        font-size: 3em;
        margin-bottom: 80px;
    }
}

.ap-team-cont {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 900px) {
        justify-content: space-around;
    }
}

.ap-team-card-cont {
    position: relative;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ap-team-card {
    position: relative;
    width: 300px;
    display: flex;
    flex-flow: column;
    border-radius: 12px;
    margin-bottom: 50px;
}

.ap-team-img {
    position: relative;
    width: 100%;
    height: 300px;
    border-radius: 12px 12px 0px 0px;
}

.ap-team-job {
    position: relative;
    width: 100%;
    height: 100px;
    background-color: #3d3d3d;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    padding: 0 10px;
    & h4 {
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: 14px;
        margin-bottom: 5px;
    }
    & h5 {
        margin: 0;
        text-align: center;
        color: #fff;
        font-size: 10px;
        font-weight: 300;
    }
}

#dan {
    position: relative;
    background-image: url("../../imgs/nfts/dan-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/dan-pfp.jpeg");
    }
}

#mike {
    position: relative;
    background-image: url("../../imgs/nfts/mike-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/mike-pfp.png");
    }
}

#lou {
    position: relative;
    background-image: url("../../imgs/nfts/lou-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/lou-pfp.png");
    }
}

#reza {
    position: relative;
    background-image: url("../../imgs/nfts/reza-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/reza-pfp.png");
    }
}

#dj {
    position: relative;
    background-image: url("../../imgs/nfts/dj-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/dj-pfp.png");
    }
}

#elton {
    position: relative;
    background-image: url("../../imgs/nfts/elton-nft.png");
    background-size: cover;
    background-repeat: no-repeat;
    transition: 1s;
    &:hover {
        background-image: url("../../imgs/pfps/elton-pfp.png");
    }
}

.ap-team-social-links {
    position: relative;
    margin-top: 5px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    & a {
        position: relative;
        width: 35px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        & img {
            max-width: 20px;
        }
    }
}