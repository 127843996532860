#horizontalWrap {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: nowrap;
    width: 600%;
    height: 100%;
    overflow-y: hidden;
}

.horizontal-inner {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.btm-nav {
    position: fixed;
    height: 30px;
    width: 100vw;
    bottom: 0px;
    z-index: 999;
    transition: "opacity 500ms linear"
}

.btm-nav-inner {
    position: relative;
    width: 70%;
    height: 2px;
    margin: 0 auto;
    background-color: rgba(119,119,119,.3);
    color: #a3a3a3;
}

.homepage-burger {
    display: none;
    @media only screen and (max-width: 1024px) {
        display: block;
    }
}

.btnTxt3 {
    text-decoration: none !important;
}