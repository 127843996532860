.strategy-page, .marketing-page, .dev-page, .blog-page, .services-page, .about-page {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    overflow-x: hidden;
}

.inner-serv {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 2%;
    z-index: 1;
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #000;
      transform-origin: top right;
      transform: skew(-45deg, 0deg);
      -ms-transform: skew(-45deg, 0deg);
      -webkit-transform: skew(-45deg, 0deg);
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
      @media screen and (max-width: 1024px) {
        width: 110%;
       }
       @media screen and (max-width: 768px) {
        width: 140%;
       }
       @media screen and (max-width: 767px) {
        width: 160%;
        }
    }
}

.serv-page-logo-cont {
    position: relative;
    & a {
        position: relative;
     & img {
        width: 350px;
        height: auto;
        @media screen and (max-width: 767px) {
          width: 220px;
        }
     }
    }   
}

.serv-page-title-cont {
    position: relative;
    & h1 {
     font-size: 5em;
     margin-top: 50px;
     margin-bottom: 0;
     max-width: 70%;
     color: #fff;
     @media screen and (max-width: 768px) {
       font-size: 3em;
     }
     @media screen and (max-width: 767px) {
      font-size: 2.5em;
    }
    @media screen and (max-width: 380px) {
      font-size: 2.2em;
    }
    }
}

.inner-serv-btm {
    position: relative;
    margin: 50px 5%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.inner-serv-btm-inner {
  position: relative;
  max-width: 1400px;
}

.serv-process {
    position: relative;
    & h4 {
        font-size: 4em;
        margin-top: 50px;
        margin-bottom: 0;
        @media screen and (max-width: 768px) {
          font-size: 3em;
        }
        @media screen and (max-width: 767px) {
          font-size: 2.5em;
        }
        @media screen and (max-width: 380px) {
          font-size: 2.2em;
        }
    }
}

.serv-item {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    padding: 50px 2%;
    border-bottom: 3px solid #000;
    @media screen and (max-width: 767px) {
      padding: 50px 0;
      flex-flow: column;
    }
}

.serv-item-img {
    position: relative;
    width: 35%;
    max-width: 200px;
    max-height: 160px;
    & svg {
      max-width: 200px;
      max-height: 160px;
      @media screen and (max-width: 767px) {
        max-height: 100px;
      }
    }
    @media screen and (max-width: 768px) {
      max-width: 150px;
    }
    @media screen and (max-width: 767px) {
      max-width: 100px;
    }
}

.serv-item-txt {
    position: relative;
    width: 65%;
    @media screen and (max-width: 767px) {
      width: 85%;
    }
    & h3 {
      font-size: 2.5em;
      @media screen and (max-width: 768px) {
        font-size: 2em;
      }
      @media screen and (max-width: 767px) {
        font-size: 1.5em;
        text-align: center;
      }
    }
    & p {
        font-size: 1.1em;
        @media screen and (max-width: 768px) {
          font-size: 1em;
        }
        @media screen and (max-width: 767px) {
          font-size: 0.8em;
        }
    }
}

.serv-item-rev {
  position: relative;
  @media screen and (max-width: 767px) {
    flex-flow: column-reverse;
  }
}

.serv-services {
    position: relative;
    margin: 50px 5%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
}

.serv-services-inner {
  position: relative;
  max-width: 1400px;
}

.serv-headline {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    & h4 {
        font-size: 4em;
        margin: 0;
        white-space: nowrap;
        @media screen and (max-width: 767px) {
          font-size: 2em;
          white-space: normal;
        }
    }
    & hr {
        width: 100%;
        height: 5px;
        background-color: #2fcc19;
        margin-left: 5%;
        margin-right: -5%;
    }
}

.serv-box-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
}

.serv-card {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    width: 350px;
    height: 385px;
    justify-content: flex-start;
    border: .5px solid #a3a3a3;
    padding: 2%;
    -webkit-transition: all 1s ease;
    border-radius: 5px;
    margin: 20px;
    background-color: #fafafa;
    @media screen and (max-width: 1024px) {
      border: 1px solid #a3a3a3;
    }
    @media screen and (max-width: 767px) {
      width: 300px;
      padding: 5%;
      height: 400px;
    }
    &:hover {
      background-color: #ffffff;
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
    }
  }

  .serv-card-top {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }
  
  .serv-svg {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    & svg {
      width: 60px;
      height: 60px;
    }
  }
  
  .serv-card-head {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: flex-start;
     & h4 {
      margin: 0;
      color: 000;
      margin-left: 10%;
      font-size: 1.2em;
    }
  }
  
  .serv-card-mid {
    position: relative;
    & p {
      font-size: 0.73em;
      line-height: 1.8em;
    }
  }

  .serv-blurb {
    position: relative;
    margin: 50px 5%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin-top: 100px;
  }

  .serv-blurb-inner {
    position: relative;
    max-width: 1200px;
    width: 60%;
    @media screen and (max-width: 1024px) {
      width: 85%;
    }
    &:after {
      content: "";
      position: absolute;
      width: 110%;
      height: 90%;
      background-color: rgba(163, 163, 163, 0.5);
      top: 30px;
      left: -5%;
      z-index: -2;
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
      @media screen and (max-width: 767px) {
        padding-bottom: 25px;
      }
    }
    & p {
      margin-top: 40px;
      font-size: 1.2em;
      line-height: 1.5em;
      font-weight: 600;
    }
  }

  .big-letter {
    position: relative;
    font-size: 6em;
    line-height: 1em;
    font-weight: 600;
    // display: inline-block;
    float: left;
  }

  .bl-box {
    position: absolute;
    height: 80px;
    width: 80px;
    background-color: rgba(0, 231, 247, 1);
    z-index: -1;
    left: -5px;
    top: 8px;
  }
  
  .static-left {
    position: absolute;
    top: 50%;
    left: -6%;
    height: 200px;
    width: 200px;
    opacity: 0.1;
  }