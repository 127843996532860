#blog {
    position: relative;
    z-index: 1;
    @media only screen and (max-width: 1024px) {
        z-index: -1;
    }
}
.blog {
    height: 100vh;
    // min-width: 100vw;
    width: 100%;
    background-color: #ffffff;
    @media only screen and (max-width: 1024px) {
        height: 100%;
        padding: 150px 80px 200px 80px;
    }
    @media only screen and (max-width: 767px) {
        padding: 150px 5%;
    }
}

.blog-cont {
    width: 100%;
    height: 100%;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 400px;
    padding-right: 100px;
    @media only screen and (min-width: 1650px) {
        padding-left: 500px;
    }
    @media only screen and (max-width: 1024px) {
        padding: 0;
    }
}

.blog-card-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
    @media only screen and (max-width: 1024px) {
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
    }
}

.blog-card {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    min-width: 350px;
    width: 350px;
    height: 350px;
    justify-content: flex-start;
    border: .5px solid #a3a3a3;
    padding: 1% 2%;
    -webkit-transition: all 1s ease;
    border-radius: 5px;
    margin-left: 35px;
    background-color: #fafafa;
    &:hover {
        background-color: #ffffff;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
    }
}

.blog-top {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    min-height: 135px !important;
}

.blog-img {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 5px;
    & img {
        position: relative;
        width: 90px;
        height: 90px;
        border-radius: 5px;
        border: 1px solid #a3a3a3;
    }
}

.blog-title {
    position: relative;
    margin-left: 15px;
    text-shadow: 2px 2px 4px #a3a3a3;
    & h2 {
        font-size: 1.1em;
        margin-bottom: 5px;
    }
    & h3 {
        font-size: 0.8em;
        margin-top: 8px;
        color: #a3a3a3;
    }
}

.blog-title-long {
    position: relative;
    & h2 {
        font-size: 1em;
    }
}


.blog-section-head {
    position: relative;
    margin-top: 5em;
    margin-bottom: 2em;
    @media only screen and (max-width: 1024px) {
        margin-top: 0;
    }
     & a {
        font-size: 6em;
        text-shadow: 2px 2px 4px #a3a3a3;
        text-decoration: none;
        color: #000 !important;
        @media only screen and (max-width: 767px) {
            font-size: 4.5em;
        }
        @media only screen and (max-width: 400px) {
            font-size: 4em;
        }
        & h3 {
            margin: 0;
        }
     }
}

.blog-categories {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    min-height: 50px !important;
}

.blog-tag {
    position: relative;
    margin-right: 8px;
    & h3 {
        margin: 0;
        padding: 0;
        font-size: 0.5em;
        color: #fff;
        line-height: 25px;
        & span {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #ff3800;
        }
    }
}

.blog-except {
    position: relative;
    font-size: 0.8em;
    min-height: 85px;
    max-height: 85px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    & p {
        margin-bottom: 8px;
    }
}

.form-message-cont {
    position: relative;
    margin-top: 20px;
    font-size: 18px;
}

#successMessage {
    color: #ffffff;
}

#errorMessage {
    color: #ff3800;
}

.input-error-message {
    position: relative;
    color: #ff3800;
    & p {
        margin-top: 0;
    }
}

.blog-card-new {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    min-width: 350px;
    width: 350px;
    height: 400px;
    justify-content: flex-start;
    border: .5px solid #a3a3a3;
    padding: 0;
    -webkit-transition: all 1s ease;
    border-radius: 5px;
    margin-left: 35px;
    background-color: #fafafa;
    @media only screen and (max-width: 1024px) {
        margin: 20px;
        border: 1px solid #a3a3a3;
    }
    @media only screen and (max-width: 400px) {
        min-width: 300px;
        width: 300px;
        height: 420px;
    }
    &:hover {
        background-color: #ffffff;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
    }
}

#blogTopOne {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/ico-difficulty.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

#blogTopTwo {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/how-to-rank-on-google-organically.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}


#blogTopThree {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/phone-picture.png");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}


#blogTopFour {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/btc-book.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}


#blogTopFive {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/piggybank.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

#blogTopSix {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/token-img-1.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}


#blogTopSeven {
    position: relative;
    width: 100%;
    height: 200px;
    min-height: 200px;
    border-radius: 5px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    padding: 20px 25px;
    background-image: url("../../imgs/ramen-soup.jpeg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: -1;
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.5);
    }
}


.blog-title-new {
    position: relative;
    color: #fff;
    margin-bottom: 10px;
    & h2 {
        font-size: 1.3em;
        margin-bottom: 5px;
    }
}

.auth-img-new {
    position: relative;
    width: 100%;
    min-height: 35px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row nowrap;
    & h3 {
        font-size: 0.8em;
        margin-top: 8px;
        color: #a3a3a3;
    }
    & img {
        max-width: 35px;
        max-height: 35px;
        border-radius: 5px;
        margin-right: 8px;
        border: 1px solid #a3a3a3;
    }
    
}

.blog-categories-new {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row wrap;
    min-height: 60px !important;
    margin-top: -15px;
    z-index: 2;
}

.blog-tag-new {
    position: relative;
    margin-right: 8px;
    & h3 {
        margin: 0;
        padding: 0;
        font-size: 0.8em;
        color: #fff;
        line-height: 30px;
        @media only screen and (max-width: 400px) {
            font-size: 0.6em;
        }
        & span {
            padding: 5px 10px;
            border-radius: 5px;
            background-color: #ff3800;
        }
    }
}


.blog-card-btm-new {
    position: relative;
    padding: 0px 25px 15px 25px;
    @media only screen and (max-width: 767px) {
        padding: 0px 20px 15px 20px;
    }
}

.blog-except-new {
    position: relative;
    font-size: 0.8em;
    min-height: 85px;
    max-height: 85px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    @media only screen and (max-width: 400px) {
        min-height: 105px;
        max-height: 105px;
    }
    & p {
        margin-bottom: 8px;
    }
}

.blog-read-more-new {
    position: relative;
    & a {
        text-decoration: none !important;
    }
}