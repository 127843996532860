.serv-page-cont {
    position: relative;
    width: 100%;
    padding: 10% 5%;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
}

.serv-page-inner {
    position: relative;
    max-width: 1000px;
    width: 100%;
}

.serv-page-top {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: 900px) {
        flex-flow: column;
    }
}

.serv-page-top-inner {
    position: relative;
    width: 50%;
    @media only screen and (max-width: 900px) {
        width: 100%;
        margin-top: 50px;
    }
}

.who-serve-cont {
    position: relative;
    height: 50px;
    & h4 {
        margin: 0;
     @media only screen and (max-width: 499px) {
        margin-top: 15px;
     }
     @media only screen and (max-width: 400px) {
        font-size: 12px;
     }
    }
}

.serv-page-top-inner-left {
    position: relative;
    & p {
        max-width: 80%;
        margin: 0;
        font-size: 18px;
        @media only screen and (max-width: 900px) {
            max-width: 100%;
        }
    }
    & h3 {
        font-size: 2em;
        margin: 0;
        margin-bottom: 50px;
    }
}

.serv-page-top-inner-right {
    position: relative;
    & h3 {
        font-size: 1.3em;
        margin: 0;
        margin-bottom: 50px;
    }
}

.serv-page-top-inner-right-inner {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
}

.serv-page-top-inner-col {
    position: relative;
    width: 50%;
}

.serv-page-block-cont {
    position: relative;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin-top: 100px;
}

.serv-block {
    position: relative;
    width: 48%;
    max-width: 550px;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    background-color: #ccc;
    padding: 50px;
    height: 250px;
    border-radius: 12px;
    margin-bottom: 2%;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
    @media only screen and (max-width: 499px) {
        padding: 50px 20px;
    }
}

.serv-block-top {
    position: relative;
    height: 50px;
    min-height: 50px;
    white-space: nowrap;
    & h3 {
        font-size: 1.5em;
        margin: 0;
        @media only screen and (max-width: 400px) {
            font-size: 1.2em;
         }
    }
}

.serv-block-body {
    position: relative;
    height: 80px;
    min-height: 80px;
    overflow: hidden;
    & p {
        margin: 0;
        line-height: 1.8em;
        font-weight: 500;
        @media only screen and (max-width: 400px) {
            font-size: 14px;
         }
        & span {
            font-weight: 100;
        }
    }
}

.serv-block-cs {
    position: relative;
    height: 20px;
    min-height: 20px;
    & a {
        color: #ff3800;
        text-decoration: none;
        font-weight: 500;
    }
}

.serv-page-cs {
    position: relative;
    margin-top: 100px;
    border: 3px solid #ccc;
    padding: 50px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
}