.blog-page {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    overflow-x: hidden;
}

.inner-serv {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    padding: 50px 2%;
    z-index: 1;
    &:after {
      content: " ";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
      background-color: #000;
      transform-origin: top right;
      transform: skew(-45deg, 0deg);
      -ms-transform: skew(-45deg, 0deg);
      -webkit-transform: skew(-45deg, 0deg);
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
      @media screen and (max-width: 1024px) {
        width: 110%;
       }
       @media screen and (max-width: 500px) {
        width: 125%;
        }
    }
}

.serv-page-logo-cont {
    position: relative;
    & a {
        position: relative;
     & img {
        width: 350px;
        height: auto;
        @media screen and (max-width: 767px) {
          width: 220px;
        }
     }
    }   
}

.serv-page-title-cont {
    position: relative;
    & h1 {
     font-size: 5em;
     margin-top: 50px;
     margin-bottom: 0;
     max-width: 70%;
     color: #fff;
     @media screen and (max-width: 768px) {
       font-size: 3em;
     }
     @media screen and (max-width: 767px) {
      font-size: 2.5em;
    }
    @media screen and (max-width: 380px) {
      font-size: 2.2em;
    }
    }
}

.blog-page-card-cont {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.bp-inner-wrapper {
  position: relative;
  max-width: 1400px;
  width: 85%;
  margin: 0px 0px 150px 0px;
}

.blog-page-category-cont {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.blog-page-category-title {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-shadow: 2px 2px 4px #a3a3a3;
  margin: 100px 0px 50px 0px;
  & h3 {
      font-size: 4em;
      margin: 0;
      white-space: nowrap;
      @media screen and (max-width: 767px) {
        font-size: 2em;
        white-space: normal;
      }
  }
  & hr {
      width: 100%;
      height: 5px;
      background-color: #2fcc19;
      margin-left: 5%;
      margin-right: -5%;
  }
}

.blog-page-cartegory-card-cont {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 1024px) {
    justify-content: center;
   }
}

.blog-page-card-inner {
  position: relative;
  margin: 20px;
}

.blog-page-card {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  min-width: 350px;
  width: 350px;
  height: 350px;
  justify-content: flex-start;
  border: .5px solid #a3a3a3;
  padding: 15px 25px;
  -webkit-transition: all 1s ease;
  border-radius: 5px;
  background-color: #fafafa;
  &:hover {
      background-color: #ffffff;
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
  }
}


.blog-page-category-title {
  width: 100%;
}


.blog-page-card-new {
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: column;
  min-width: 350px;
  width: 350px;
  height: 400px;
  justify-content: flex-start;
  border: .5px solid #a3a3a3;
  padding: 0;
  -webkit-transition: all 1s ease;
  border-radius: 5px;
  background-color: #fafafa;
  @media screen and (max-width: 1024px) {
    border: 1px solid #a3a3a3;
  }
  @media screen and (max-width: 767px) {
    min-width: 300px;
    width: 300px;
    height: 420px;
  }
  &:hover {
      background-color: #ffffff;
      box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
  }
}