#contact {
    position: relative;
    width: 100%;
    min-width: 100vw;
    height: 100vh;
    background-color: #000;
    padding: 0 100px;
    @media only screen and (max-width: 1024px) {
        height: 100%;
        padding: 50px 80px 100px 80px;
        z-index: 1;
        &:before {
            position: absolute;
            content: "";
            width: 100%;
            height: 240px;
            transform: skewY(5deg);
            left: 0;
            top: -50px;
            background-color: #000000;
            box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0, 0, 0, 0.32);
          }
    }
    @media only screen and (max-width: 767px) {
        padding: 50px 5% 50px 5%;
    }
}

.contact {
    position: relative;
    width: 100%;
    height: 100vh;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    @media only screen and (max-width: 1024px) {
        height: 100%;
    }
}

.contact-section-head {
    position: relative;
    margin-top: 4em;
    margin-bottom: 0;
    @media only screen and (max-width: 767px) {
        margin: 0;
    }
     & h3 {
      font-size: 6em;
      color: #fff;
      margin: 0;
      @media only screen and (max-width: 767px) {
        font-size: 4.5em;
      }
      @media only screen and (max-width: 400px) {
        font-size: 4em;
    }
     }
  }

  .contact-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        flex-flow: column;
    }
}

.form-cont {
    position: relative;
    min-width: 600px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 768px) {
        min-width: 100%;
        margin-top: 50px;
    }
}

.contact-form {
    position: relative;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 350px;
    @media only screen and (max-width: 767px) {
        width: 300px;
    }
    & input[type="submit"] {
        position: relative;
        width: 50%;
        background-color: #ff3800;
        height: 40px;
        border-radius: 5px;
        font-size: 0.8em;
        font-weight: 600;
        color: #fff;
        border: none;
        -webkit-transition: all 1s ease;
        -webkit-appearance: none;
        text-transform: uppercase;
        font-weight: 900;
        &:hover {
            background-color: #fff;
            color: #ff3800;
        }
    }
}

.form-input-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    width: 100%;
    & label {
        color: #fff;
        margin-bottom: 8px;
        font-weight: 600;
    }
    & input {
        min-width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
        margin-bottom: 20px;
        min-height: 30px;
        padding: 0 3%;
    }
    & textarea {
        min-width: 100%;
        border-radius: 5px;
        border: none;
        outline: none;
        margin-bottom: 20px;
        min-height: 50px;
        padding: 5px 3%;
    }
}


.bdl-info-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    min-width: 450px;
    @media only screen and (max-width: 768px) {
        min-width: 100%;
        margin-top: 50px;
    }
    & img {
        max-width: 150px;
        align-self: center;
    }
}

.copyright {
    text-align: center;
    color: #ffffff;
    margin: 8px 0;
    font-weight: 600;
}

.tnc-cont {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    text-align: center;
    font-size: 12px;
    & a  {
        margin: 8px 0;
        color: #ffffff;
        text-decoration: none;
        -webkit-transition: all 1s ease;
        font-weight: 600;
        &:hover {
            color: #ff3800;
        }
    }
}