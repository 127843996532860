.footer {
    position: relative;
    // margin-top: 100px;
    min-height: 220px;
    width: 100%;
    background-color: #000;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    padding: 20px 5%;
}

.footer-logo-cont {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    & img {
        max-width: 250px;
    }
}

.footer-copyright {
    position: relative;
    width: 100%;
    text-align: center;
    & span {
        color: #fff;
        font-weight: 400;
    }
}