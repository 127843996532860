.about {
    position: relative;
    height: 100vh;
    width: 100%;
    @media only screen and (max-width: 1024px) {
        height: 100%;
    }
    &:after {
        content: '';
        position: absolute;
        height: 100vh;
        width: 10px;
        background: #fff;
        left: -4px;
        top: 0;
        @media only screen and (max-width: 1024px) {
            width: 100%;
            height: 20px;
            bottom: auto;
            top: -10px;
            background: #fff;
        }
    }
}

.s2-cont {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: row nowrap;
    @media only screen and (max-width: 1024px) {
        flex-flow: column;
        padding: 50px 80px 200px 80px;
    }
    @media only screen and (max-width: 767px) {
        padding: 5% 5% 150px 5%;
    }
}

.s2-left-txt {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: flex-end;
    text-align: left;
    width: 500px;
    font-size: 1.8em;
    line-height: 2.5em;
    padding-left: 80px;
    margin-top: -3em;
    @media only screen and (max-width: 1024px) {
        width: 100%;
        height: 400px;
        justify-content: flex-end;
        padding: 0;
        margin-top: 0;
        align-items: center;
    }
    @media only screen and (max-width: 767px) {
        font-size: 1.8em;
        width: 100%;
        height: 250px;
    }
    & h2 {
        // background-image: url('../../imgs/database.jpg');
        background-image: linear-gradient(to right, #000000 , #a3a3a3);
        -webkit-background-size: cover;
        -moz-background-size: cover;
        background-size: cover;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        will-change: background-position;
        @media only screen and (max-width: 1024px) {
            width: 500px;
        }
        @media only screen and (max-width: 768px) {
            text-align: right;
        }
        @media only screen and (max-width: 767px) {
            font-size: 1.2em;
            line-height: 1.5em;
            width: 90%;
            margin: 0;
        }
        @media only screen and (max-width: 400px) {
            font-size: 1.1em;
        }
    }
}

.s2-mid-text {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    width: 450px;
    align-items: center;
    margin-top: -15em;
    /* padding-left: 7em;
    padding-right: 7em; */
    font-size: .85em;
    line-height: 1.5em;
    margin-left: -15em;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
        width: 100%;
        height: 200px;
        justify-content: center;
        padding: 0;
        margin: 0;
        align-items: flex-start;
    }
    & p {
        position: relative;
        @media only screen and (max-width: 1024px) {
            max-width: 400px;
        }
        @media only screen and (max-width: 450px) {
            max-width: 90%;
        }
        & span {
            color: #3d3d3d;
            font-weight: 600;
        }
    }
    
}

.s2-right-img {
    width: 875px;
    height: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    @media only screen and (max-width: 1024px) {
        width: 100%;
        height: 400px;
        justify-content: center;
        padding: 0;
        margin: 0;
        align-items: flex-end;
    }
    @media only screen and (max-width: 768px) {
        margin-top: 50px;
    }
    @media only screen and (max-width: 767px) {
        margin-top: 0px;
        height: 320px;
    }
}

.s2-img1-cont {
  max-width: 20em;  
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-left: 8em;
  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 12em;
  }
  @media only screen and (max-width: 767px) {
    margin-right: 8em;
    max-width: 250px;
  }
  @media only screen and (max-width: 450px) {
    margin-right: 6em;
    max-width: 200px;
  }
  & img {
    width: 100%;
    height: auto;
    display: block;
    box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
  }
}

.s2-img2-cont {
  max-width: 20em; 
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-left: -3em;
  margin-top: -3em;
  @media only screen and (max-width: 767px) {
    max-width: 250px;
  }
  @media only screen and (max-width: 450px) {
    max-width: 200px;
  }
    & img {
        width: 100%;
        height: auto;
        display: block;
        box-shadow: 0.75rem 0.85rem 1.75rem 0 rgba(0,0,0,.32);
    }
}

.about-btn-cont {
    position: relative;
    & a {
        text-decoration: none !important;
    }
}